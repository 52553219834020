<template>
  <div>
    <h4 class='text-sm text-gray-500'>아래 관련된 정보를 먼저 삭제 또는 변경 바랍니다.</h4>
    <ol class='mb-12'>
      <li 
        v-for='investment in sameEntityInvestments'
        :key='`same-entity-investment-${investment.id}`'
        class='py-2'>
        {{investment.investment_name}}  
      </li>
    </ol>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import investmentsApi  from '@/api/v1/investments'

export default {
  name: 'EntityInvestments',
  data () {
    return {
      sameEntityInvestments: [],
    }
  },
  watch: {
    'drilldownEntityId': {
      handler: function () {
        this.getFilteredInvestments()
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('entities', [
      'drilldownEntityId',
    ]),
    hasInvestments () {
      return this.sameEntityInvestments.length > 0
    },
    getInvestmentParams () {
      return {
        entity_id: this.drilldownEntityId,
        dashboard_view_id: 0
      }
    },
  },
  methods: {
    getFilteredInvestments () { 
      investmentsApi.getInvestments(this.getInvestmentParams).then(resp => { // we should move this call out to be part of the call for the entity details content
        this.sameEntityInvestments = resp
      })
    },
  }
}
</script>
